















import { computed, ComputedRef, defineComponent, provide, ref, Ref, watch } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useQuery, UseQueryReturn, useResult, UseResultReturn } from '@vue/apollo-composable'
import { Purchases_Purchase, Sales_Sale } from '@/models/generated/graphql/ErpBackend'
import { GET_PURCHASE, GET_SALE } from '@/api/graphql/Constants/Orders'
export default defineComponent({
  name: 'InvoiceOrder',
  props: {
    type: {
      type: String as () => 'AR' | 'AP',
      required: true
    },
    id: {
      type: String as () => number | string,
      required: true
    },
    errorMessages: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const enabled: Ref<boolean> = ref(false)
    const error: Ref<string> = ref('')
    const success: Ref<string> = ref('')
    const orderType: ComputedRef<string> = computed(() => {
      return props.type === 'AR' ? 'ST' : 'PT'
    })

    function validateId (id: string) {
      emit('input', { value: id })
      if (Number(id) > 0) {
        if (enabled.value) {
          refetch()
        } else {
          enabled.value = true
        }
      } else {
        enabled.value = false
        success.value = ''
        error.value = ''
      }
    }

    const { result, loading, refetch, onError }: UseQueryReturn<Sales_Sale | Purchases_Purchase, { id: string | number }> =
      useQuery(
        orderType ? GET_PURCHASE : GET_SALE,
        () => ({
          id: props.id
        }),
        () => ({
          enabled: enabled.value
        })
      )
    onError((e) => {
      // @ts-ignore
      if (e?.networkError.includes('did not return')) {
        error.value = `Invalid ${orderType.value}`
        success.value = ''
        emit('input', { value: '' })
      }
    })
    const order: UseResultReturn<Sales_Sale | Purchases_Purchase | null> = useResult(result, null)
    watch(order, value => {
      if (value?.id) {
        success.value = `Valid ${orderType.value}`
        error.value = ''
      }
    })

    return {
      validateId,
      loading,
      error,
      success
    }
  }
})
