














































































import formTemplate from '@/components/forms/formTemplate.vue'
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  provide,
  reactive,
  ref,
  Ref,
  watch
} from '@vue/composition-api'
import {
  ApolloClients,
  useMutation,
  UseMutationReturn
} from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import {
  Create__Invoices_ArInvoice__Input,
  Mutation,
  MutationCreate__Invoices_ArInvoiceArgs
} from '@/models/generated/graphql/ErpBackend'
import {
  CREATE__ACCOUNTING_AP_INVOICE,
  CREATE__ACCOUNTING_AR_INVOICE
} from '@/api/graphql/Constants/Accounting'
import datePicker from '@/components/autocompletes/datePicker.vue'
import Currency from '@/components/fields/Currency.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { ValidateVue3ErrorObject } from '@/lib/validation'
import { c } from '@/lib/Currency'
import InvoiceOrder from '@/components/autocompletes/InvoiceOrder.vue'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'

export default defineComponent({
  name: 'NewInvoice',
  components: {
    'new-data-form': formTemplate,
    'date-picker': datePicker,
    'currency': Currency,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    'invoice-order': InvoiceOrder
  },
  props: {
    type: {
      type: String as () => 'AR' | 'AP',
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    const succeeded: Ref<boolean> = ref(false)
    const failed: Ref<boolean> = ref(false)

    const invoiceInput: Create__Invoices_ArInvoice__Input = reactive({
      amount: '',
      date_due: '',
      date_sent: '',
      order_id: '',
      reference: '',
      status_id: '7'
    })
    const errors = reactive({
      amount: { value: '', validator: () => invoiceInput.amount !== '' ? '' : 'This is required' },
      date_due: { value: '', validator: () => invoiceInput.date_due !== '' ? '' : 'This is required' },
      date_sent: { value: '', validator: () => invoiceInput.date_sent !== '' ? '' : 'This is required' },
      order_id: { value: '', validator: () => invoiceInput.order_id !== '' ? '' : 'Valid ID required' }
    })
    const isValidInput: ComputedRef = computed(() => {
      for (const key in errors) {
        // @ts-ignore
        if (errors[key].value !== '') return false
      }
      return true
    })

    const footerMessage = ref(`New ${props.type} Invoice`)

    function updateInput ({ key, value }: { key: string, value: any }): void {
      // @ts-ignore
      invoiceInput[key] = value
      // @ts-ignore
      errors[key].value = ''
    }

    const {
      mutate: CreateInvoiceMutation,
      loading,
      onError,
      onDone
    }: UseMutationReturn<Mutation, MutationCreate__Invoices_ArInvoiceArgs> =
    useMutation(props.type === 'AR' ? CREATE__ACCOUNTING_AR_INVOICE : CREATE__ACCOUNTING_AP_INVOICE)
    onError((error) => {
      failed.value = true
      footerMessage.value = error?.message ?? 'Could not create AR Invoice'
    })
    onDone(() => {
      succeeded.value = true
      footerMessage.value = `Created ${props.type} Invoice Successfully`
      emit('success')
    })

    function CreateInvoice () {
      ValidateVue3ErrorObject(errors)
      if (isValidInput.value) {
        invoiceInput.amount = GetDjangoMoneyCompatibleInput(c(invoiceInput.amount).value)
        CreateInvoiceMutation({ input: invoiceInput })
      }
    }

    onMounted(() => {
      document.getElementById('invoice-order-field')?.focus()
    })

    return {
      invoiceInput,
      errors,
      CreateInvoice,
      loading,
      isValidInput,
      updateInput,
      succeeded,
      failed,
      footerMessage
    }
  }
})
