
































import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { VoidInvoice } from '@/api/graphql/Constants/Accounting'
import store from '../../store/store'
import { Invoices_ApInvoice, Invoices_ArInvoice } from '@/models/generated/graphql/ErpBackend'

export default defineComponent({
  name: 'VoidInvoices',
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    invoices: {
      type: Array as () => Array<Invoices_ArInvoice | Invoices_ApInvoice>,
      required: true
    },
    type: {
      type: String as () => 'AR' | 'AP',
      required: true
    }
  },
  setup (props, { emit }) {
    const succeeded = ref(false)
    const isLoading = ref(false)

    async function submit () {
      isLoading.value = true
      try {
        for (const invoice of props.invoices) {
          await VoidInvoice(invoice.id, props.type, invoice.is_voided)
        }
        store.dispatch('notifications/createSnackbar', { message: 'Successfully voided invoice(s)', color: 'success', timeout: 0 })
        succeeded.value = true
      } catch (error) {
        store.dispatch('notifications/createSnackbar', { message: 'Could not void', color: 'error', timeout: 0 })
      } finally {
        isLoading.value = false
      }
    }

    return {
      succeeded,
      isLoading,
      submit,
      emit
    }
  }
})
