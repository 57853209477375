
















































import Currency from '@/components/fields/Currency.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { computed, defineComponent, provide, reactive, ref } from '@vue/composition-api'
import { apolloClient } from '@/api/graphql/apollo'
import { ApolloClients, useMutation, UseMutationReturn } from '@vue/apollo-composable'
import {
  Create__Invoices_ApPayment__Input,
  Mutation, MutationCreate__Invoices_ApPaymentArgs
} from '@/models/generated/graphql/ErpBackend'
import {
  CREATE_INVOICE_AP_PAYMENT,
  CREATE_INVOICE_AR_PAYMENT
} from '@/api/graphql/Constants/Accounting'
import store from '@/store/store'

export default defineComponent({
  name: 'AddPaymentToInvoice',
  components: {
    'currency': Currency,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    invoiceId: {
      type: String,
      required: true
    },
    invoiceType: {
      type: String as () => 'AP' | 'AR',
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apolloClient
    })

    interface AlertType {
      message: string,
      color: 'success' | 'error' | 'warning' | 'primary',
      timeout?: number
    }
    function alert (alert: AlertType) {
      store.dispatch('notifications/createSnackbar', alert)
    }
    const succeeded = ref(false)

    const paymentInput: Create__Invoices_ApPayment__Input = reactive({
      amount: '',
      invoice_id: props.invoiceId,
      is_posted: false,
      reference: ''
    })

    const mutation = computed(() => props.invoiceType === 'AR' ? CREATE_INVOICE_AR_PAYMENT : CREATE_INVOICE_AP_PAYMENT)

    const {
      mutate,
      loading,
      onError,
      onDone
    }: UseMutationReturn<Mutation, MutationCreate__Invoices_ApPaymentArgs> = useMutation(mutation)
    onError((error) => {
      // @ts-ignore
      const message = error.networkError || 'Could not add payment to invoice'
      alert({ message: message, color: 'error' })
    })
    onDone(() => {
      alert({ message: 'Added invoice payment successfully', color: 'success', timeout: 0 })
      succeeded.value = true
      emit('refetch')
    })

    function submit () {
      if (paymentInput.amount !== '') {
        mutate({ input: paymentInput })
      } else {
        alert({ message: 'Enter an amount', color: 'warning', timeout: 4000 })
      }
    }

    return {
      succeeded,
      loading,
      submit,
      paymentInput
    }
  }
})
