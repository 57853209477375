var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('new-data-form',{attrs:{"succeeded":_vm.succeeded,"failed":_vm.failed,"errors":{
    panel0: !_vm.isValidInput
  }},scopedSlots:_vm._u([{key:"header0",fn:function(){return [_vm._v("Invoice Information")]},proxy:true},{key:"panel0",fn:function(){return [_c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-start":"","align-start":"","wrap":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('invoice-order',{attrs:{"type":_vm.type,"id":_vm.invoiceInput.order_id,"error-messages":_vm.errors.order_id.value},on:{"input":function($event){return _vm.updateInput({ key: 'order_id', value: $event.value })}}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('date-picker',{attrs:{"date":_vm.invoiceInput.date_due,"config":{
              label: 'Due Date',
              maxDate: ''
            },"errors":_vm.errors.date_due.value},on:{"updated":function($event){return _vm.updateInput({ key: 'date_due', value: $event.value })}}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('date-picker',{attrs:{"date":_vm.invoiceInput.date_sent,"config":{
              label: 'Date Sent'
            },"errors":_vm.errors.date_sent.value},on:{"updated":function($event){return _vm.updateInput({ key: 'date_sent', value: $event.value })}}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('currency',{attrs:{"label":"Invoice Amount","value":_vm.invoiceInput.amount,"error-messages":_vm.errors.amount.value},on:{"input":function($event){return _vm.updateInput({ key: 'amount', value: $event })}}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-text-field',{attrs:{"label":"Reference","box":"","data-cy":"reference"},model:{value:(_vm.invoiceInput.reference),callback:function ($$v) {_vm.$set(_vm.invoiceInput, "reference", $$v)},expression:"invoiceInput.reference"}})],1)],1)],1)]},proxy:true},{key:"dataActions",fn:function(){return [_c('cancel-button',{attrs:{"success":_vm.succeeded},on:{"click":function($event){return _vm.$emit('close')}}}),_c('submit-button',{attrs:{"disabled":_vm.succeeded,"loading":_vm.loading},on:{"click":_vm.CreateInvoice}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-footer',{attrs:{"color":_vm.succeeded ? 'success' : _vm.failed ? 'error' : 'primary',"data-cy":"new-data-form-footer"}},[_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_vm._t("footerMessage",[_c('h3',{class:{ 'white--text': !_vm.succeeded, 'grey--text text--darken-2': _vm.succeeded },attrs:{"data-cy":"footer-sale"}},[_vm._v(_vm._s(_vm.footerMessage))])])],2)],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }